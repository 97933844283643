import Vue from "vue";
import Vuex from "vuex";
import menus from "./modules/menus";
import VueCookies from "vue-cookies";

Vue.use(Vuex);

const token = VueCookies.get("tc_token");
const uname = VueCookies.get("tc_uname");

export default new Vuex.Store({
  state: {
    token,
    uname,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      VueCookies.set("tc_token", token);
    },
    clearToken(state) {
      state.token = null;
      VueCookies.set("tc_token", "");
      VueCookies.set("tc_uname", "");
    },
    setuName(state, uname) {
      state.uname = uname;
      VueCookies.set("tc_uname", uname);
    },
  },
  actions: {},
  modules: {
    menus,
  },
  getters: {
    token: (state) => state.token,
    uName: (state) => state.uname,
  },
});
