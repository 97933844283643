import axios from "../../utils/axios";
export default {
  sys_auth_get_tree(ay) {
    return axios.post(
      "admin/roles.php",
      Object.assign({ op: "sys_auth_get_tree" }, ay)
    );
  },
  roles_delete(ay) {
    return axios.post(
      "admin/roles.php",
      Object.assign({ op: "roles_delete" }, ay)
    );
  },
  roles_save(ay) {
    return axios.post(
      "admin/roles.php",
      Object.assign({ op: "roles_save" }, ay)
    );
  },
  roles_list(ay) {
    return axios.post(
      "admin/roles.php",
      Object.assign({ op: "roles_list" }, ay)
    );
  },
};
