import axios from "../../utils/axios";
export default {
  article_types_kinds_delete(ay) {
    return axios.post(
      "admin/article_types_kinds.php",
      Object.assign({ op: "article_types_kinds_delete" }, ay)
    );
  },
  article_types_kinds_save(ay) {
    return axios.post(
      "admin/article_types_kinds.php",
      Object.assign({ op: "article_types_kinds_save" }, ay)
    );
  },
  article_types_kinds_list(ay) {
    return axios.post(
      "admin/article_types_kinds.php",
      Object.assign({ op: "article_types_kinds_list" }, ay)
    );
  },
};
