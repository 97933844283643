import axios from "../../utils/axios";
export default {
  news_update(ay) {
    return axios.post(
      "admin/news.php",
      Object.assign({ op: "news_update" }, ay)
    );
  },
  news_detail(ay) {
    return axios.post(
      "admin/news.php",
      Object.assign({ op: "news_detail" }, ay)
    );
  },
  news_delete(ay) {
    return axios.post(
      "admin/news.php",
      Object.assign({ op: "news_delete" }, ay)
    );
  },
  news_save(ay) {
    return axios.post("admin/news.php", Object.assign({ op: "news_save" }, ay));
  },
  news_list(ay) {
    return axios.post("admin/news.php", Object.assign({ op: "news_list" }, ay));
  },
};
