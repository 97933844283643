import axios from "../../utils/axios";
export default {
  news_cartypes_delete(ay) {
    return axios.post(
      "admin/news_cartypes.php",
      Object.assign({ op: "news_cartypes_delete" }, ay)
    );
  },
  news_cartypes_save(ay) {
    return axios.post(
      "admin/news_cartypes.php",
      Object.assign({ op: "news_cartypes_save" }, ay)
    );
  },
  news_cartypes_list(ay) {
    return axios.post(
      "admin/news_cartypes.php",
      Object.assign({ op: "news_cartypes_list" }, ay)
    );
  },
};
