import axios from "../../utils/axios";
export default {
  config_delete(ay) {
    return axios.post(
      "admin/config.php",
      Object.assign({ op: "config_delete" }, ay)
    );
  },
  config_save(ay) {
    return axios.post(
      "admin/config.php",
      Object.assign({ op: "config_save" }, ay)
    );
  },
  config_row(ay) {
    return axios.post(
      "admin/config.php",
      Object.assign({ op: "config_row" }, ay)
    );
  },
};
