import axios from "../../utils/axios";
export default {
  get_user_infos(ay) {
    return axios.post(
      "admin/users.php",
      Object.assign({ op: "get_user_infos" }, ay)
    );
  },
  import_users(ay) {
    return axios.post(
      "admin/users.php",
      Object.assign({ op: "import_users" }, ay)
    );
  },
  export_users(ay) {
    let ay_target = Object.assign({ op: "export_users" }, ay);
    let ay_data = [];
    for (let p in ay_target) {
      ay_data.push(
        encodeURIComponent(p) + "=" + encodeURIComponent(ay_target[p])
      );
    }

    return axios({
      method: "get",
      url: "admin/users.php?" + ay_data.join("&"),
      responseType: "blob", //二进制流
    });

    // return axios.get("admin/users.php?" + ay_data.join("&"));
  },
  users_delete(ay) {
    return axios.post(
      "admin/users.php",
      Object.assign({ op: "users_delete" }, ay)
    );
  },
  users_save(ay) {
    return axios.post(
      "admin/users.php",
      Object.assign({ op: "users_save" }, ay)
    );
  },
  users_list(ay) {
    return axios.post(
      "admin/users.php",
      Object.assign({ op: "users_list" }, ay)
    );
  },
};
