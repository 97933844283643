<template>
  <el-container>
    <el-aside width="200px" style="background-color: rgb(84, 92, 100)">
      <SideMenu></SideMenu>
    </el-aside>
    <el-container>
      <el-header>
        <strong>后台管理系统</strong>

        <div class="header-avatar">
          <!-- <el-avatar size="medium" :src="userInfo.avatar"></el-avatar> -->

          <el-dropdown>
            <span class="el-dropdown-link">
              {{ $store.getters.uName
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>
                <router-link :to="{ name: 'UserCenter' }">个人中心</router-link>
              </el-dropdown-item> -->
              <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main>
        <Tabs></Tabs>
        <div style="margin: 0 15px">
          <router-view />
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import SideMenu from "./aside/SideMenu";
import Tabs from "./aside/Tabs";
export default {
  name: "layout",
  components: {
    SideMenu,
    Tabs,
  },
  data() {
    return {
      userInfo: {
        id: "",
        username: "",
        avatar: "",
      },
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    logout() {
      this.$store.commit("clearToken");
      this.$store.commit("changeRouteStatus", false);
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped>
.header-avatar {
  float: right;
  /* width: 210px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.el-dropdown-link {
  cursor: pointer;
}

.el-header {
  background-color: #23262e;
  color: #fff;
  text-align: center;
  line-height: 60px;
}
.el-main {
  padding-top: 5px;
  padding-left: 0px;
}
.el-dropdown {
  color: #fff;
}
</style>
