import axios from "../../utils/axios";
export default {
  gbooks_update(ay) {
    return axios.post(
      "admin/gbooks.php",
      Object.assign({ op: "gbooks_update" }, ay)
    );
  },
  gbooks_delete(ay) {
    return axios.post(
      "admin/gbooks.php",
      Object.assign({ op: "gbooks_delete" }, ay)
    );
  },
  gbooks_save(ay) {
    return axios.post(
      "admin/gbooks.php",
      Object.assign({ op: "gbooks_save" }, ay)
    );
  },
  gbooks_list(ay) {
    return axios.post(
      "admin/gbooks.php",
      Object.assign({ op: "gbooks_list" }, ay)
    );
  },
};
