import axios from "../../utils/axios";
export default {
  news_types_list_all(ay) {
    return axios.post(
      "admin/news_types.php",
      Object.assign({ op: "news_types_list_all" }, ay)
    );
  },
  news_types_delete(ay) {
    return axios.post(
      "admin/news_types.php",
      Object.assign({ op: "news_types_delete" }, ay)
    );
  },
  news_types_save(ay) {
    return axios.post(
      "admin/news_types.php",
      Object.assign({ op: "news_types_save" }, ay)
    );
  },
  news_types_list(ay) {
    return axios.post(
      "admin/news_types.php",
      Object.assign({ op: "news_types_list" }, ay)
    );
  },
};
