import axios from "../../utils/axios";
export default {
  silders_delete(ay) {
    return axios.post(
      "admin/silders.php",
      Object.assign({ op: "silders_delete" }, ay)
    );
  },
  silders_save(ay) {
    return axios.post(
      "admin/silders.php",
      Object.assign({ op: "silders_save" }, ay)
    );
  },
  silders_list(ay) {
    return axios.post(
      "admin/silders.php",
      Object.assign({ op: "silders_list" }, ay)
    );
  },
};
