import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/layout/layout";
import store from "../store";
// import xjc from "./xjc";
import api from "../api";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta: { title: "停管办" },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/",
    redirect: "/Index",
    children: [],
  },
  {
    path: "/Index",
    name: "home",
    meta: { title: "停管办" },
    component: Layout,
    children: [
      {
        path: "/Index",
        name: "Index",
        title: "主页",
        iconCls: "fa fa-dashboard", // 图标样式class
        component: () => import("../views/Main.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

let ay_router = [];

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    window.document.title = to.meta.title;
  }
  console.log("beforeEach", store.getters.token);
  if (store.getters.token != null) {
    //登录成功
    if (to.path === "/login") {
      next({ path: "/" });
    } else {
      // console.log("beforeEach");

      // let that = this;
      (async function get_menu() {
        let hasRoute = store.state.menus.hasRoutes;
        console.log("hasRoute", hasRoute);
        if (!hasRoute) {
          await api.menu_list({}).then((res) => {
            let ay = res.data.data;
            store.commit("setMenuList", ay);
            ay.forEach((it) => {
              if (it.children.length > 0) {
                it.children.forEach((item) => {
                  if (!ay_router.includes(item.name)) {
                    ay_router.push(item.name);
                    let mm = menuToRoute(item);
                    router.addRoute("home", mm);
                  }
                });
              }
            });
          });
          hasRoute = true;
          store.commit("changeRouteStatus", hasRoute);
          next({ path: to.path });
        } else {
          let ay = store.state.menus.menuList;
          // store.commit("setMenuList", ay);
          console.log("menuList", "get", ay);
          ay.forEach((it) => {
            if (it.children.length > 0) {
              it.children.forEach((item) => {
                if (!ay_router.includes(item.name)) {
                  ay_router.push(item.name);
                  let mm = menuToRoute(item);
                  router.addRoute("home", mm);
                }
              });
            }
          });
          next();
        }
      })();
      next();
    }
  } else {
    //未登录做验证
    if (to.meta.requireAuth) {
      if (to.path === "/login") {
        next();
      }
      next({ path: "/login" });
    } else {
      if (to.path === "/login") {
        next();
      } else {
        next({ path: "/login" });
      }
    }
  }
});

// 导航转成路由
const menuToRoute = (menu) => {
  if (!menu.component) {
    return null;
  }

  let route = {
    name: menu.name,
    path: menu.path,
    meta: {
      icon: menu.icon,
      title: menu.title,
    },
  };
  route.component = () => import("@/views/" + menu.component + ".vue");

  return route;
};

export default router;
