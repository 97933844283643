import axios from "../../utils/axios";
export default {
  news_teams_delete(ay) {
    return axios.post(
      "admin/news_teams.php",
      Object.assign({ op: "news_teams_delete" }, ay)
    );
  },
  news_teams_save(ay) {
    return axios.post(
      "admin/news_teams.php",
      Object.assign({ op: "news_teams_save" }, ay)
    );
  },
  news_teams_list(ay) {
    return axios.post(
      "admin/news_teams.php",
      Object.assign({ op: "news_teams_list" }, ay)
    );
  },
};
