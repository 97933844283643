import axios from "../../utils/axios";
export default {
  admins_add_other(ay) {
    return axios.post(
      "admin/admins.php",
      Object.assign({ op: "admins_add_other" }, ay)
    );
  },
  admins_delete(ay) {
    return axios.post(
      "admin/admins.php",
      Object.assign({ op: "admins_delete" }, ay)
    );
  },
  admins_save(ay) {
    return axios.post(
      "admin/admins.php",
      Object.assign({ op: "admins_save" }, ay)
    );
  },
  admins_list(ay) {
    return axios.post(
      "admin/admins.php",
      Object.assign({ op: "admins_list" }, ay)
    );
  },
};
