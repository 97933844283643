import axios from "axios";
import { Message, Loading } from "element-ui";
import VueCookies from "vue-cookies";
import { makeSign } from "./tools";
import store from "../store/index";

// axios默认配置
axios.defaults.timeout = 10000; // 超时时间
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

let loading_obj = null;

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    // config.headers["Content-Type"] = "application/json;charset=UTF-8";
    loading_obj = Loading.service({
      text: "加载中。。。。",
      lock: true,
    });
    // if (config.headers === undefined) {
    //   config.headers = {};
    // }
    // config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    // config.transformRequest = [
    //   function (data) {
    //     let ay_data = [];
    //     for (let p in data) {
    //       ay_data.push(
    //         encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
    //       );
    //     }
    //     return ay_data.join("&");
    //   },
    // ];

    config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    if (VueCookies.get("tc_token")) {
      config.headers.Authorization = `Yunda${VueCookies.get("tc_token")}`;
    }
    let time = Math.floor(new Date().getTime() / 1000);
    let app_id = Math.ceil(Math.random() * 100000);
    let sign_obj = {
      time: time,
      app_id: app_id,
    };
    let sign = makeSign(sign_obj);
    sign_obj["sign"] = sign;
    //登录成功附加的两个参数
    if (store.getters.token) {
      sign_obj["token"] = store.getters.token;
      // sign_obj["uid"] = store.getters.uid;
    }

    if (config.method === "post") {
      for (let p in sign_obj) {
        config.data[p] = sign_obj[p];
      }
      config.transformRequest = [
        function (data) {
          let ay_data = [];
          for (let p in data) {
            ay_data.push(
              encodeURIComponent(p) + "=" + encodeURIComponent(data[p])
            );
          }
          return ay_data.join("&");
        },
      ];
    } else {
      let url = config.url;
      let ay_url = [];
      for (let p in sign_obj) {
        ay_url.push(p + "=" + sign_obj[p]);
      }
      let url_param = ay_url.join("&");
      if (url.indexOf("?") > -1) {
        config.url += "&" + url_param;
      } else {
        config.url += "?" + url_param;
      }
    }

    return config;
  },
  (error) => Promise.reject(error.response)
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    loading_obj.close();
    // console.log(response.data, "axios");
    if (response.data.result == 400) {
      Message({
        message: response.data.msg,
        type: "warning",
      });
      return Promise.reject(response);
    }

    return Promise.resolve(response);
  },
  (error) => {
    loading_obj.close();
    return Promise.reject(error); // 返回接口返回的错误信息
  }
);
export default axios;
