<template>
  <el-menu
    :default-active="this.$store.state.menus.editableTabsValue"
    class="el-menu-vertical-demo"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
    :style="{ height: clientHeigth + 'px' }"
  >
    <router-link to="/index">
      <el-menu-item
        index="Index"
        @click="selectMenu({ name: 'Index', title: '首页' })"
      >
        <template slot="title">
          <i class="el-icon-s-home"></i>
          <span slot="title">首页</span>
        </template>
      </el-menu-item>
    </router-link>

    <el-submenu :index="meu.name" v-for="meu in menuList" :key="meu.name">
      <template slot="title">
        <i :class="meu.icon"></i>
        <span>{{ meu.title }}</span>
      </template>

      <router-link
        :to="item.path"
        v-for="item in meu.children"
        :key="item.name"
      >
        <el-menu-item
          :index="item.name"
          @click="selectMenu(item)"
          style="background: #333"
        >
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </template>
        </el-menu-item>
      </router-link>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  name: "SideMenu",
  data() {
    return {
      menuList: [],
      clientHeigth: 500,
    };
  },
  mounted() {
    this.clientHeigth = window.innerHeight - 2;
    this.menuList = this.$store.state.menus.menuList;
    window.onresize = () => {
      return (() => {
        this.clientHeigth = window.innerHeight - 2;
        // window.screenWidth = document.body.clientWidth
        // that.screenWidth = window.screenWidth
      })();
    };
  },
  computed: {
    // menuList: {
    //   get() {
    //     return this.$store.state.menus.menuList;
    //   },
    // },
  },
  watch: {
    "$store.state.menus.menuList"(val) {
      this.menuList = val;
    },
  },
  methods: {
    selectMenu(item) {
      this.$store.commit("addTab", item);
    },
  },
};
</script>

<style scoped>
.el-menu-vertical-demo {
  height: 100%;
}
/deep/.el-submenu .el-menu-item {
  padding: 0;
  min-width: 150px;
}
/deep/ el-menu-item {
  padding: 0 !important;
}
</style>
