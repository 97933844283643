import axios from "../../utils/axios";
export default {
  menu_list(ay) {
    return axios.post(
      "admin/menus.php",
      Object.assign({ op: "menu_list" }, ay)
    );
  },
  menus_delete(ay) {
    return axios.post(
      "admin/menus.php",
      Object.assign({ op: "menus_delete" }, ay)
    );
  },
  menus_save(ay) {
    return axios.post(
      "admin/menus.php",
      Object.assign({ op: "menus_save" }, ay)
    );
  },
  menus_list_list(ay) {
    return axios.post(
      "admin/menus.php",
      Object.assign({ op: "menus_list_list" }, ay)
    );
  },
};
