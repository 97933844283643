import axios from "../../utils/axios";
export default {
  articles_delete(ay) {
    return axios.post(
      "admin/articles.php",
      Object.assign({ op: "articles_delete" }, ay)
    );
  },
  articles_save(ay) {
    return axios.post(
      "admin/articles.php",
      Object.assign({ op: "articles_save" }, ay)
    );
  },
  articles_list(ay) {
    return axios.post(
      "admin/articles.php",
      Object.assign({ op: "articles_list" }, ay)
    );
  },
};
