import md5 from "md5";
import underscore from "underscore";

function makeSign(obj) {
  let obj_temp = Object.assign({ key: process.env.VUE_APP_MD5_KEY }, obj);
  let ay_sort = [];
  for (var p in obj_temp) {
    ay_sort.push({
      name: p,
      value: obj_temp[p],
    });
  }
  //排序
  ay_sort = underscore.sortBy(ay_sort, "name");
  //去重
  ay_sort = underscore.uniq(ay_sort, true, "name");
  let ay_url = [];
  ay_sort.forEach((item) => {
    ay_url.push(item.name + "=" + item.value);
  });
  let url = ay_url.join("&");
  return md5(url).toUpperCase();
}

function parserDate(date) {
  let t = Date.parse(date);
  if (!isNaN(t)) {
    return new Date(Date.parse(date.replace(/-/g, "/")));
  } else {
    return new Date();
  }
}
function dateFormat(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}

function date_add_day(data_format, days, fmt) {
  let dataCurPar = Date.parse(parserDate(data_format)) / 1000; //转换为时间戳
  dataCurPar += 86400 * days; //加days天后的时间戳
  let dataDefPar = new Date(parseInt(dataCurPar) * 1000); //转换为时间
  return dateFormat(fmt, dataDefPar);
}

export { makeSign, parserDate, dateFormat, date_add_day };
