import axios from "../../utils/axios";
export default {
  citys_delete(ay) {
    return axios.post(
      "admin/citys.php",
      Object.assign({ op: "citys_delete" }, ay)
    );
  },
  citys_save(ay) {
    return axios.post(
      "admin/citys.php",
      Object.assign({ op: "citys_save" }, ay)
    );
  },
  citys_list(ay) {
    return axios.post(
      "admin/citys.php",
      Object.assign({ op: "citys_list" }, ay)
    );
  },
};
