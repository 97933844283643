import axios from "../../utils/axios";
export default {
  depts_delete(ay) {
    return axios.post(
      "admin/depts.php",
      Object.assign({ op: "depts_delete" }, ay)
    );
  },
  depts_save(ay) {
    return axios.post(
      "admin/depts.php",
      Object.assign({ op: "depts_save" }, ay)
    );
  },
  depts_list(ay) {
    return axios.post(
      "admin/depts.php",
      Object.assign({ op: "depts_list" }, ay)
    );
  },
};
